import * as React from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import CookieBanner from './cookie_banner'
import Easter from "../components/easter";


const Layout_page = ({ pageTitle, children }) => {

  return (
    <>
      <div className='bg-def_grey_1'>
        <Header></Header>
        <main className="flex flex-col items-center min-w-full min-h-screen">
          <div className='text-3xl font-medium'><Easter text="🥚🐇" /></div>
          {children}
        </main>
        <Footer></Footer>

      </div>
      <CookieBanner></CookieBanner>
    </>
  )
}

export default Layout_page