import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby";
import { useState } from "react"

import Seo from "../components/seo"
import Layout_page from "../components/layout_page";


// needed to use font awsome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

export default function BloggPage() {

    const data = useStaticQuery(graphql`
    {
        allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(blogg)/"}}) {
            edges {
            node {
                id
                html
                frontmatter {
                slug
                title
                date_updated
                category
                }
            }
            }
        }
    }
    `);

    const posts = data.allMarkdownRemark.edges;

    const [filterName, setFilterName] = useState("")
    const [category, setCategory] = useState(getCategories())

    // filters posts base
    const filteredPosts = posts.filter(edge => edge.node.frontmatter.title.toLowerCase().includes(filterName)
        && category.indexOf(edge.node.frontmatter.category) != -1
        && edge.node.frontmatter.category != "empty")


    function getCategories() {
        let categories = []

        posts.map(({ node }, index) => {

            const cat = node.frontmatter.category;

            // avoids duplicates in categories
            if (categories.indexOf(cat) == -1 && cat != "empty") {
                categories.push(node.frontmatter.category)
            }
        })
        return categories
    }

    const allCategories = getCategories()

    const FilteredPosts = (
        filteredPosts.map(({ node }, index) => {
            const truncatedHtml = node.html.length > 100 ? node.html.substring(0, 200) + '...' : node.html;
            return (
                <Link to={"/blogg/" + node.frontmatter.slug} className="block max-w-lg md:px-0 py-4 text-white bg-neutral-800 rounded-lg hover:bg-neutral-700 shadow-2xl">
                    <div className="mx-8 mt-4 mb-4">
                        <h2 className="mt-2 font-semibold text-2xl">{node.frontmatter.title}</h2>
                        <p className="mt-4 text-gray-400" dangerouslySetInnerHTML={{ __html: truncatedHtml }}></p>
                        <div className="mt-4">
                            <p className="text-sm inline-block px-2 py-1 rounded-full text-white bg-neutral-700 border-2 border-neutral-500">{node.frontmatter.category}</p>
                        </div>
                    </div>
                </Link>
            )
        })
    )


    function FilterByCategory() {

        return (
            <>
                <div className="mb-8">
                    <label className="text-white mr-4" htmlFor="category">Kategori:</label>
                    <select name="categories" id="category" onChange={handleCategoryOption} defaultValue="alla">
                        <option key="alla" value="alla">Alla</option>
                        {allCategories.map((item, index) => {
                            return (
                                <option key={index} value={item}>{item}</option>
                            )
                        })}
                    </select>
                </div>
            </>
        )

    }


    const handleCategoryOption = (e) => {

        const value = e.target.value

        if (value === "alla") {
            setCategory(allCategories)
        } else {
            setCategory(value)
        }

    }



    return (


        <div className="bg-def_grey_1 page_formatting">

            <Layout_page>

                <h1 className="mt-20 text-white">Blogg</h1>

                <div className="flex mt-10 mb-10">
                    <input className="rounded-full border-2 border-transparent" type="text" placeholder="Sök inlägg..." value={filterName} onChange={(e) => setFilterName(e.target.value.toLowerCase())} />
                    <button onClick={() => setFilterName("")}><FontAwesomeIcon className="inline-block text-white bg-neutral-500 hover:bg-neutral-400 text-xl ml-2 px-3 py-2 rounded-full" icon={solid('xmark')} /></button>
                </div>

                {FilterByCategory()}

                {filteredPosts.length === 0 &&
                    <p className="text-white text-center text-2xl font-semibold mt-10">Kunde inte hitta något om <br /><i>"{filterName}" under kategori {category}</i></p>
                }


                <div className="grid grid-cols-1 grid-flow-dense gap-7 md:gap-10 mx-6 mt-4 mb-16">
                    {FilteredPosts}

                </div>



            </Layout_page>

        </div>
    )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Blogg" />
